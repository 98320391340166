html {
  background-color: #F5F5F8;
}

.paperShadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.circleOpacity {
  opacity: .8;
  cursor: pointer;
}

.outlineCircleRight {
  filter: drop-shadow(1px 0 0 white) drop-shadow(-1px 0 0 white) drop-shadow(0 -1px 0 white) drop-shadow(0 1px 0 white) drop-shadow(2px 0 0 green) drop-shadow(-2px 0 0 green) drop-shadow(0 -2px 0 green) drop-shadow(0 2px 0 green);
}

.outlineCircleLeft {
  filter: drop-shadow(1px 0 0 white) drop-shadow(-1px 0 0 white) drop-shadow(0 -1px 0 white) drop-shadow(0 1px 0 white) drop-shadow(2px 0 0 green) drop-shadow(-2px 0 0 green) drop-shadow(0 -2px 0 green) drop-shadow(0 2px 0 green);
}

/* .transforScaleOn {
  transform: scale(1.01);
  transition: all 0.5s;
  transform-origin: 50% 50% ;
}
.transforScaleOff {
  transform: scale(1);
  transition: all 0.5s;
} */

.graphContainer {
  overflow: auto;
}

@media all and (max-width: 900px) {
 /*  .graphContainer {
    max-height: calc(100vh - 190px);
    overflow: auto;

  } */
}

/* Testing */
/* .circleShadowBlue {
  -webkit-filter: drop-shadow( rgba(4, 171, 193, 0.24) 0px 3px 8px);
  filter: drop-shadow(rgba(4, 171, 193, 0.24) 0px 3px 8px);
}
.circleShadowPurple {
  -webkit-filter: drop-shadow( rgba(0, 0, 0, 0.24) 0px 3px 8px);
  filter: drop-shadow(rgba(0, 0, 0, 0.24) 0px 3px 8px);
} */
/* Testing */